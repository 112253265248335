import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { DocState } from "./state";

const defaultState: DocState = {
  docs: [],
  docDetail: null,
  docsFilter: {
    doc_type: "FA",
    date_from: new Date(new Date().getTime() - 150 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10), //"2022-05-01",
    date_to: new Date().toISOString().slice(0, 10),
    status: "",
  },
};

export const docModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
