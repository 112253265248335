import { IDoc, IDocDetail, IDocsFilter } from "@/interfaces";
import { DocState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setDocs(state: DocState, payload: IDoc[]) {
    state.docs = payload;
  },
  setDocsFilter(state: DocState, payload: IDocsFilter) {
    state.docsFilter = payload;
  },
  setDocDetail(state: DocState, payload: IDocDetail) {
    state.docDetail = payload;
  },
};

const { commit } = getStoreAccessors<DocState, State>("");

export const commitSetDocDetail = commit(mutations.setDocDetail);
export const commitSetDocs = commit(mutations.setDocs);
export const commitSetDocsFilter = commit(mutations.setDocsFilter);
