import { api } from "@/api";
import { ActionContext } from "vuex";
import { IDocsFilter } from "@/interfaces";
import { State } from "../state";
import { DocState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { commitSetDocDetail, commitSetDocs, commitSetDocsFilter } from "./mutations";
import { dispatchCheckApiError } from "../main/actions";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";
import axios from "axios";

type MainContext = ActionContext<DocState, State>;

export const actions = {
  async actionGetDocs(context: MainContext, payload: IDocsFilter) {
    commitSetDocsFilter(context, payload);
    const loadingNotification = { content: "Loadind documents", showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      const response = await api.searchDocs(context.rootState.main.token, payload);

      if (response) {
        commitSetDocs(context, response.data);
      }
      commitRemoveNotification(context, loadingNotification);
    } catch (error) {
      // todo find a way
      console.log(error);
      let errorMessage = "Doc not found";
      // todo need to fix it
      if (axios.isAxiosError(error)) {
        const errorResponse = error.response;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const errorData = errorResponse ? errorResponse.data : {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        errorMessage = errorData.detail ? errorData.detail : errorMessage;
      }

      await dispatchCheckApiError(context, error);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: "error",
        content: errorMessage,
      });
    }
  },
  async actionGetDocDetail(context: MainContext, docId: string) {
    console.log(docId);
    try {
      const response = await api.getDocDetail(
        context.rootState.main.token,
        //encodeURIComponent(docId),
        btoa(docId),
      );

      if (response) {
        commitSetDocDetail(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<DocState, State>("");

export const dispatchGetDocs = dispatch(actions.actionGetDocs);
export const dispatchGetDocDetail = dispatch(actions.actionGetDocDetail);
