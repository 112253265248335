import { DocState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  docs: (state: DocState) => state.docs,
  docsFilter: (state: DocState) => state.docsFilter,
  docDetail: (state: DocState) => state.docDetail,
  oneDoc: (state: DocState) => (docId: string) => {
    console.log(docId);
    const filteredDocs = state.docs.filter((doc) => doc.id === docId);
    if (filteredDocs.length > 0) {
      return { ...filteredDocs[0] };
    }
  },
};

const { read } = getStoreAccessors<DocState, State>("");

export const readDoc = read(getters.oneDoc);
export const readDocDetail = read(getters.docDetail);
export const readDocs = read(getters.docs);
export const readDocsFilter = read(getters.docsFilter);
